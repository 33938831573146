

/* ANGULAR MDC */

$mdc-theme-primary: #aa1f20; // primary color
$mdc-theme-secondary: #999999; // secondary color

@import "~@angular-mdc/theme/material";

//
// Image List
//

.masonry-image-list {
    @include mdc-image-list-masonry-columns(2);
}

@media (min-width: 599px) {
  .masonry-image-list {
    @include mdc-image-list-masonry-columns(3);
  }
}

@media (min-width: 950px) {
  .masonry-image-list {
    @include mdc-image-list-masonry-columns(4);
  }
}



/* ANGULAR MATERIAL */

/* Custom fotofil palette - generated with: http://mcg.mbitson.com/#!?mcgpalette0=%23aa1f20 */
$fotofil-primary: (
    50 : #f5e4e4,
    100 : #e6bcbc,
    200 : #d58f90,
    300 : #c46263,
    400 : #b74141,
    500 : #aa1f20,
    600 : #a31b1c,
    700 : #991718,
    800 : #901213,
    900 : #7f0a0b,
    A100 : #ffafb0,
    A200 : #ff7c7d,
    A400 : #ff494b,
    A700 : #ff3032,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$fotofil-secondary: (
    50 : #f3f3f3,
    100 : #e0e0e0,
    200 : #cccccc,
    300 : #b8b8b8,
    400 : #a8a8a8,
    500 : #999999,
    600 : #919191,
    700 : #868686,
    800 : #7c7c7c,
    900 : #6b6b6b,
    A100 : #fdeaea,
    A200 : #f8bcbc,
    A400 : #ff8181,
    A700 : #ff6868,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$spa-primary: mat-palette($fotofil-primary);
$spa-accent: mat-palette($fotofil-secondary);

// The warn palette is optional (defaults to red).
$spa-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$spa-theme: mat-light-theme($spa-primary, $spa-accent, $spa-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($spa-theme);

// Create your Sass color vars (will be available in all the project)
$ff-primary: mat-color($spa-primary);
$ff-accent: mat-color($spa-accent);
$ff-warn: mat-color($spa-warn);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
p {
    max-width: 720px;
}
footer {
    font-size: 12px;
}

footer .ff-footer {
    padding: 16px;
    margin-top: 42px;
}
footer .rw-branding {
    margin-top: 16px;
    padding: 24px;
    background-color: #f1f3f4;
}

table {
  width: 100%;
  input {
    border: none;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    padding: 4px;
  }
}

.validation-error {
    border-color: $ff-warn;
}

.validation-error-text {
    color: $ff-warn;
}

.new-password-input {
    margin-left: 16px;
}

.current-page {
    text-decoration: underline !important;
}

.home-link {
    text-decoration: none;
    color: inherit;
}

.truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-radio-button ~ .mat-radio-button {
    margin-left: 16px;
}

.mat-list-base .mat-list-item img.mat-list-avatar {
    border-radius: 0;
}

.toolbar-links-md {
    overflow: auto;
}

.ff-sidenav-container {
    width: 300px;
    padding: 20px;
}

.ff-page-container {

}

.mdc-image-list__supporting {
    display: table;
    table-layout: fixed;
    width:100%;
}
.mdc-image-list__label {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
}

.ff-image-list-page-container {
    /* width: 100%;
    max-width: 950px;
    padding-bottom: 60px; */
    padding: 12px 34px;
    @media (min-width: 950px) {
        padding: 12px 46px;
    }
}

.ff-image-list-page-content {
    width: 100%;
    max-width: 950px;
    padding-bottom: 60px;
}

/* Single photo page */

.ff-single-photo-page-container {
    padding: 12px;
    img {
        width: auto;
        max-width: 100%;
    }
}

.ff-single-photo__image-title {
    max-width: 60vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ff-single-photo-page-content {
    width: 100%;
    max-width: 950px;
    padding-bottom: 60px;
}

.ff-single-photo__image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F3F3F3;
    margin-bottom: 60px;
}

.ff-single-photo__image-categories-label {
    margin-right: 12px;
}

.ff-single-photo__image-description {
    margin-bottom: 40px;
}

.ff-single-photo__image-info-container {
    margin-bottom: 80px;
}

.ff-single-photo__image-info-content-edit form > * {
    width: 100%;
    max-width: 720px;
}

.ff-article-page-container {
    padding: 12px;
}

.ff-article-page-content {
    width: 100%;
    max-width: 950px;
    padding-bottom: 60px;
}

/* Search */
.ff-search {
    margin-bottom: 24px;
}
.ff-search form > * {
    width: 100%;
    margin-bottom: 24px;
}

mdc-image-list-item {
    a {
        overflow: hidden;
    }
    .hover-image {
        visibility: hidden;
        position: absolute;
        top: 0;
        z-index: 999;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        transform-origin: top center;
        transition: transform .3s ease;
    }
    &:hover {
        .hover-image {
            visibility: visible;
            transform: scale(1.3);
        }
    }
}
.ff-user-info {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ff-user-info-divider {
    margin-bottom: 20px;
}

.mat-tree-node {
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.ff-paginator {
    margin-bottom: 16px;
}

/* Utilities */
.banner-spacer {
  flex: 1 1 auto;
}

// Custom config - https://material.angular.io/guide/typography
$ff-custom-config-one: mat-typography-config(
  $font-family: 'monospace',
  $title: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);
// Default
$config: mat-typography-config();

// Custom title
h1.ff-custom-title {
  @include mat-typography-level-to-styles($ff-custom-config-one, title);
}

.ff-btn-typo {
    @include mat-typography-level-to-styles($config, button);
    /* padding: 0 16px;
    height: 36px; */
}


/* Body classes */

.ff-article-page-content__text a {
    word-break: break-all;
}

body.contact {
    .ff-article-page-content__text-and-image {
        display: flex;
        flex-direction: column;
        @media (min-width: 599px) {
            flex-direction: row;
        }
    }
    .ff-article-page-content__text {
        flex: 1 1 auto;
        margin-right: 24px;
    }
    .ff-article-page-content__bg-image {
        width: 240px;
        min-width: 240px;
        height: 240px;
        background-image: url('./assets/ff-contact-bg.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}


body.customers {
    .ff-article-page-content__text-and-image {
        display: flex;
        flex-direction: column;
    }
    .ff-article-page-content__text {
        flex: 1 1 auto;
        margin-bottom: 36px;
    }
    .ff-article-page-content__bg-image {}
}

/* $ff-customer-image-width: 570px;
$ff-customer-image-height: 714px;
$ff-customer-aspect-ratio-value-one:  $ff-customer-image-height / $ff-customer-image-width;
$ff-customer-aspect-ratio-value-two:  $ff-customer-image-width / $ff-customer-image-height;
$ff-customer-aspect-ratio-percent:  $ff-customer-aspect-ratio-value-one * 100%;
$ff-customer-image-max-height:  950px / $ff-customer-aspect-ratio-value-two;

body.customers {
    .ff-article-page-content__text-and-image {
        display: flex;
        flex-direction: column;
    }
    .ff-article-page-content__text {
        flex: 1 1 auto;
        margin-bottom: 36px;
    }
    .ff-article-page-content__bg-image {
        width: 570px;
        min-width: 570px;
        height: 714px;
        background-image: url('./assets/ff-customers-bg.jpg');
        background-repeat: no-repeat;
        position: relative;
        min-height: $ff-customer-image-max-height; 
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: -1;
            height: 0;
            padding-bottom: $ff-customer-aspect-ratio-percent;
            background: url('./assets/ff-customers-bg.jpg') center top / 100% auto no-repeat;
        }
    }
} */

